import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPurchaseDataAction } from "../../../actions/product/action";
import Loader from "../../common/Loader";
import Toaster from "../../common/Toaster";
//importing styles
import "./styles.scss";
import { navigate } from "gatsby";

const OrderComponent = ({ state, getPurchaseDataAction}) => {
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(async () => {
    setLoading(true);
    let uId = localStorage.getItem("userId");

    if (!uId || uId === null) {
      navigate("/onboarding/step-one");
    } else {
      let response = await getPurchaseDataAction(
        localStorage.getItem("userId")
      );

      setLoading(false);
      if (response.error) {
        if (response.errorData === "Please authenticate") {
          setToastrMsg("Your session has expired. please login again!");
          doRefresh((prev) => prev + 1);
          setTimeout(() => {
            localStorage.clear();
            navigate("/login");
          }, 500);
        }
      } else {
        if (
          response &&
          response.responseData.success &&
          response.responseData.data &&
          response.responseData.data.cart &&
          response.responseData.data.cart.purchases &&
          response.responseData.data.cart.purchases.length >= 0
        ) {
          setOrders(response.responseData.data.cart.purchases);
        } else {
          setToastrMsg("Orders not found!");
          doRefresh((prev) => prev + 1);
          // navigate("/");
        }

        // setToastrMsg(response.errorData);
        // doRefresh((prev) => prev + 1);
      }
    }
  }, []);

  return (
    <>
      <Loader isLoading={loading} />
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="orders-component">
        <div className="orders-title">All Orders</div>
        <div className="orders-sub-title">Starting from most recent</div>
        <div className="orders-sub-title-ul"></div>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="orders-grid"
        >
          {orders.map(
            (order) =>
              order.orders.length > 0 && (
                <Fragment key={order.uniqueId}>
                  <Grid item xs={12} md={4} className="order-item">
                    <div className="order-container">
                      <div className="order-image">
                        {order.orders[0] &&
                          (!order.orders[0].others ||
                            (order.orders[0].others &&
                              order.orders[0].others == "")) && (
                            <img
                              src={
                                order.orders[0].product &&
                                order.orders[0].product.images &&
                                order.orders[0].product.images.length > 0 &&
                                order.orders[0].product.images[0].image
                              }
                            />
                          )}
                        {order.orders[0] &&
                          order.orders[0].others === "SWATCH" && (
                            <img src="/images/common/Swatch Image.jpg" />
                          )}
                        {order.orders[0] &&
                          order.orders[0].others === "BOOK" && (
                            <img src="/images/common/pantones/1.webp" />
                          )}
                      </div>
                      <div className="order-number">
                        Order# : <span>{order.uniqueId}</span>
                      </div>
                      <button 
                      className="view-more-button"
                      onClick={()=>navigate(`/order/${order.uniqueId}`)}
                      >
                        View {order.orders.length} {order.orders.length > 1 ? 'items':'item'}
                      </button>
                    </div>
                  </Grid>
                </Fragment>
              )
          )}
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state.data.userDetails,
});

export default connect(mapStateToProps, {
  getPurchaseDataAction,
})(OrderComponent);
