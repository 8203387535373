import React from 'react';
import OrdersIndex from '../../components/cards/orders';


const Orders = ({}) => {
  return (
    <>
    <OrdersIndex /> 
    </>
  )
}

export default Orders